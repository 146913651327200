import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { VideoBlog } from "../../../components/video";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";
const VidImg = require("../../../assets/img/integration/bamboohr/video_img_zohod.png");

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");
const image1 = require("../../../assets/images/freshservice_blog/1_1.png");
const image2 = require("../../../assets/images/zohod_blog/1.png");
const image3 = require("../../../assets/images/zohod_blog/2.png");
const image4 = require("../../../assets/images/freshservice_blog/3_3.png");
const image5 = require("../../../assets/images/zohod_blog/3.png");
const image6 = require("../../../assets/images/zohod_blog/4.png");
const image7 = require("../../../assets/images/zohod_blog/5.png");
const image8 = require("../../../assets/images/zohod_blog/6.png");
const image9 = require("../../../assets/images/zohod_blog/7.png");
const image10 = require("../../../assets/images/zohod_blog/8.png");
const image11 = require("../../../assets/images/zohod_blog/9.png");
const image12 = require("../../../assets/images/zohod_blog/10.png");
const image13 = require("../../../assets/images/zohod_blog/11.png");
const image14 = require("../../../assets/images/zohod_blog/12.png");
const image15 = require("../../../assets/images/zohod_blog/13.png");
const image16 = require("../../../assets/images/freshservice_blog/l_1.png");
const image17 = require("../../../assets/images/freshservice_blog/l_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Free Zoho Desk Chatbot Guide for your Business. Build Zoho Desk Chatbot for automated workplace support. "
        description="Build Zoho Desk Chatbot without any coding in minutes Workativ’s platform provides easy tools to quickly build your Zoho Desk chatbot and automate workplace support "
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={image1}
        schema={ldSchema}
      />
      <section className="market_main_wrapper chatbot-guide-blog-main-container">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header">
                            The Ultimate Guide to Zoho Desk Chatbot 
                          </h1>
                        </div>
                      </div>
                      <section className="table_contents_wrapper">
                        <h3 class="font-section-sub-header-small">
                          Table of contents
                        </h3>
                        <div className="table_contents_link font-section-normal-text-medium">
                          <AnchorLink offset={180} href="#section1">
                            1. What is Zoho Desk Chatbot?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section2">
                            2. How does Zoho Desk Chatbot works?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section3">
                            3. What is Zoho Desk Chatbot used for?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section4">
                            4. Best Zoho Desk Chatbot platform
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section5">
                            5. Smarter AI-powered Zoho Desk automation with Zoho
                            Desk Chatbot
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section6">
                            6. How to Build Zoho Desk Chatbot Without coding
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section7">
                            7. Business benefits of Zoho Desk chatbot
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section8">
                            8. Conclusion
                          </AnchorLink>
                        </div>
                      </section>

                      {/* Header Section */}
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 market_wrapper_page line-height-2">
                        <p class="font-section-normal-text line-height-2">
                          It’s a no-brainer that better employee experience
                          equals better customer service. A recent
                          <a href="https://www.genesys.com/en-gb/resources/workforce-engagement-management-how-to-engage-your-team-for-the-best-customer-experience">
                            &nbsp;Frost & Sullivan study ,
                          </a>{" "}
                          on Workforce Engagement Management (WEM) found that
                          89% of surveyed companies felt that improving contact
                          center engagement will significantly enhance the
                          quality of customer experience. And companies that
                          fully adopt WEM solutions are 82% more likely to
                          provide a better quality of customer experience and
                          96% more likely to achieve overall profitability. This
                          means companies that invest in delivering the right
                          tools and incorporating elements that engage,
                          recognize and enliven — and empower their workforce to
                          do their jobs more effectively — reap the benefits of
                          happier, more productive employees. Long story short,
                          if your agents are using the Zoho Desk ticketing
                          tool for your employee/customer IT support, then it’s
                          high time you made their lives easier with
                          <a href="https://workativ.com/conversational-ai-platform/zoho-desk-chatbot">
                             &nbsp;Zoho Desk Chatbot.
                          </a>
                        </p>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 market_wrapper_page line-height-2">
                        {/* Section 1 */}
                        <div id="section1">
                          <h2 className="font-section-sub-header-small-bold">
                            What is Zoho Desk Chatbot?
                          </h2>
                          <p class="font-section-normal-text line-height-2">
                            Zoho Desk Chatbot is an AI-powered no-code platform
                            for building contextual chatbots with automated
                            workflows for various business apps like
                            <a href="https://workativ.com/conversational-ai-platform/slack-chatbot-guide">
                              &nbsp;Slack
                            </a>
                             &nbsp;or&nbsp;
                            <a href="https://workativ.com/conversational-ai-platform/microsoft-teams-vs-slack">
                              Microsoft Teams.
                            </a>
                            Using Zoho Desk Chatbot, companies benefit by
                            automating repetitive issues, save costs, reduce
                            time to resolution, deliver faster support and more.
                          </p>
                        </div>

                        {/* Section 2 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section2"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            How does Zoho Desk Chatbot works? 
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            <a href="https://workativ.com/conversational-ai-platform/zoho-desk-chatbot">
                              Zoho Desk chatbots
                            </a>{" "}
                            use Natural Language Processing to understand a
                            user’s intention or utterances for initiating a
                            conversation with them as well as to extract
                            required data from a user’s query and pass it to the
                            automation to resolve the user’s issue in
                            real-time. So, think of how intelligent chatbots
                            work, but with the Zoho Desk chatbot, it can not
                            only have those benefits, but also extend the
                            functionalities of the Zoho Desk tool using advanced
                            integration and workflows. 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            Zoho Desk collects and delivers interactions from
                            multiple media (email, phone, chat, social media, a
                            self-service site, forums, and forms) in one
                            location. Workflow rules may automate tasks like
                            ticket assignment, service escalation, notification
                            rules, and time-based activities. The system
                            includes reports that may be customised and
                            scheduled, happiness ratings, and a graphical
                            dashboard for evaluating client satisfaction.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            Users can also make specific modifications to the
                            solution, such as changing tabs, adding new
                            departments, altering email templates, establishing
                            business hours, and adding help folders. Zoho Desk
                            may also be linked with the in-house systems of
                            customers.{" "}
                          </p>
                        </div>

                        {/* Section 3 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 market_wrapper_page"
                          id="section3"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            What is Zoho Desk chatbot used for?
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            Zoho Desk is a virtualized help desk platform that
                            aims to make customer support easier for
                            organizations of all kinds. Customer support
                            tickets, a customer support portal, contract
                            administration, and report generation are all
                            important aspects of Zoho.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            A Zoho Desk chatbot can be quite helpful not just
                            for your users but also for the agents, as it helps
                            automate user interactions on repetitive tasks and
                            helps your team focus on more important and complex
                            tasks that require human touch.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            We have compiled a list of most repetitive tasks
                            that you can automate using{" "}
                            <a href="https://workativ.com/conversational-ai-platform/zoho-desk-chatbot">
                              Zoho Desk chatbot
                            </a>
                            : 
                          </p>

                          <ul
                            class="font-section-normal-text"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>Creating a ticket </li>
                            <li>Updating a ticket </li>
                            <li>Retrieving a ticket’s details </li>
                            <li>Deleting a ticket </li>
                            <li>Creating a contact </li>
                            <li>Updating a contact </li>
                            <li>Retrieving a contact’s details </li>
                            <li>Deleting a contact </li>
                          </ul>
                        </div>

                        {/* Section 4 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section4"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            Best Zoho Desk Chatbot platform
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            Workativ Assistant - no code platform with both
                            chatbot and workflow automation tools.
                          </p>
                        </div>

                        <NocodeWrapper />

                        {/* Section 5 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 mb-0 market_wrapper_page"
                          id="section5"
                        >
                          <h2 className="font-section-sub-header-small-bold">
                            Smarter AI-powered Zoho Desk automations with Zoho
                            Desk Chatbot
                          </h2>

                          <p className="font-section-normal-text line-height-2">
                            Workativ Assistant adds a conversational layer on
                            top of Zoho Desk enabling{" "}
                            <a href="https://workativ.com/conversational-ai-platform/ai-self-service-chatbot">
                              AI self-service for your employees&nbsp;
                            </a>
                             that they’ll love. With the conversational
                            self-service, you can do the following on Zoho Desk
                            without having to leave your Slack or Microsoft
                            Teams app.  
                          </p>

                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              1. Creating a ticket
                            </span>
                            — Get rid of the hassle of navigating never-ending
                            menus on boring self-service portals. We are humans
                            and we crave for two-way interactions. Build and
                            make Workativ Assistant’s Zoho Desk
                            chatbot available to your employees and let them
                            raise Zoho Desk tickets by having a quick chat with
                            it.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              2. Updating a ticket
                            </span>
                            — Whenever an agent wants to update a Zoho Desk
                            ticket, they can do so by simply bringing
                            up Workativ Assistant’s Zoho Desk chatbot. The Zoho
                            Desk chatbot will take care of it after getting the
                            details to be updated from the agent. 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              3. Retrieving a ticket’s details
                            </span>
                            — After raising a Zoho Desk ticket, employees can
                            check their ticket’s status whenever they feel like
                            with Workativ Assistant’s 
                            <a href="https://workativ.com/conversational-ai-platform/zoho-desk-chatbot">
                              &nbsp;Zoho Desk chatbot.
                            </a>{" "}
                            They’ll just have to open their Slack/Microsoft
                            Teams app on their mobile phone/desktop and interact
                            with Workativ Assistant’s Zoho Desk chatbot there. 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              4. Deleting a ticket
                            </span>
                            — Employees can delete a Zoho Desk ticket instantly
                            with Workativ Assistant’s Zoho Desk chatbot if they
                            raised it by mistake. 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              5. Creating a contact
                            </span>
                            — In case a new employee is joining your company,
                            your agent/HR can add them to your company’s Zoho
                            Desk workspace for internal IT/HR support
                            with Workativ Assistant’s Zoho Desk chatbot. Just
                            ask the Zoho Desk chatbot to add a contact to Zoho
                            Desk by providing the required details via chat and
                            it will get it done in a jiff. 
                          </p>

                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              6. Updating a contact
                            </span>
                            — Whether an employee wants to change his/her
                            address of permanent residence or any other detail
                            in Zoho Desk, he or she can do so effortlessly
                            with Workativ Assistant’s Zoho Desk chatbot. They’ll
                            just have to verify it’s them via{" "}
                            <a href="https://workativ.com/conversational-ai-platform/twilio-chatbot">
                              Twilio OTP verification,&nbsp;
                            </a>{" "}
                            provide the required data, and the Zoho Desk
                            chatbot will take care of the rest for them. 
                          </p>
                          <ExistingBlogCta
                            ContentCta="Auto-resolve 60% of Zoho Desk Employee Queries."
                            ButtonText="Book a Demo"
                            isColor="white"
                            backgroundImage={cta_2}
                            mobileBackgroundImage={cta_2_mob}
                          />
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              7. Retrieving a contact’s details
                            </span>
                            — At any time when a help desk agent wants to know
                            about the employee who raised the ticket, she or he
                            can do so by just asking Workativ Assistant’s Zoho
                            Desk chatbot. . 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              8. Deleting a contact
                            </span>
                            — When there’s an outgoing employee, your help desk
                            agent can delete them from your Zoho Desk workspace
                            just by having a conversation
                            with Workativ Assistant’s Zoho Desk chatbot.   
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            As you can see, not only do your help desk agents
                            benefit but your employees as well when you combine
                            Zoho Desk with Workativ Assistant. And don’t worry,
                            access to certain Zoho Desk actions
                            on Workativ Assistant’s chatbots are restricted so
                            that only the ones who are authorized can make use
                            of them
                          </p>
                        </div>

                        {/* Section 6 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 mb-0 market_wrapper_page"
                          id="section6"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            How to Build a Zoho Desk chatbot
                          </h3>
                          <h3 className="font-section-normal-text-medium">
                            Step 1. Creating a bot workspace
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            When you sign up for Workativ Assistant, you’ll be
                            required to name your workspace. You can provide the
                            name of  your organization as a workspace name.    
                          </p>
                          <img
                            loading="lazy"
                            src={image1}
                            className="mb-5"
                          ></img>
                          <h3 className="font-section-normal-text-medium">
                            Step 2. Download your bot
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Download a prebuilt ManageEngine Zoho Desk from the
                            bot Marketplace    
                          </p>
                          <img
                            loading="lazy"
                            src={image2}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image3}
                            className="mb-5"
                          ></img>
                          <p className="font-section-normal-text line-height-2">
                            So that’s all there is to the initial set up of your
                            workspace. Next, let’s download Zoho Desk workflows
                          </p>
                          <h3 className="font-section-normal-text-medium">
                            Step 3. Setting up a Zoho Desk app workflow 
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-1 pb-0">
                            Lets download Zoho Desk app workflow from
                            marketplace   
                          </p>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Click on Download from Marketplace  
                          </p>
                          <img
                            loading="lazy"
                            src={image4}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Select the Zoho Desk application   
                          </p>
                          <img
                            loading="lazy"
                            src={image5}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Select the app workflow  
                          </p>
                          <img
                            loading="lazy"
                            src={image6}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Connect with your Zoho Desk account    
                          </p>
                          <img
                            loading="lazy"
                            src={image7}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image8}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image9}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Test the app workflow   
                          </p>
                          <img
                            loading="lazy"
                            src={image10}
                            className="mb-5"
                          ></img>

                          <h3 className="font-section-normal-text-medium">
                            Step 4: Now add workflow to the Zoho Desk chatbot 
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Add app workflow to the dialog conversation  
                          </p>
                          <img
                            loading="lazy"
                            src={image11}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Select the workflow  
                          </p>
                          <img
                            loading="lazy"
                            src={image12}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Provide the inputs & save it   
                          </p>
                          <img
                            loading="lazy"
                            src={image13}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image14}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Test your Zoho Desk chatbot    
                          </p>
                          <img
                            loading="lazy"
                            src={image15}
                            className="mb-5"
                          ></img>

                          <h3 className="font-section-normal-text-medium mb-0 pb-0">
                            Step 5: Deploy Zoho Desk chatbot in your favourite
                            chat channel   
                          </h3>
                          <img
                            loading="lazy"
                            src={image16}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image17}
                            className="mb-0"
                          ></img>
                          <img loading="lazy"></img>
                        </div>

                        <div className="build_needs">
                          <div class="col-lg-12 col-md-12 col-12 pl-0 center_features_header">
                            <h2 className="font-section-header mb-2">
                              Steps to build Zoho Desk Chatbot with workflow
                              automation
                            </h2>
                          </div>
                        </div>

                        <VideoBlog
                          videoURL={
                            "https://www.youtube-nocookie.com/embed/-jr4y0vJVS8?autoplay=1%22"
                          }
                          imgURL={VidImg}
                        />

                        {/* Section 7 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 mb-0 market_wrapper_page"
                          id="section7"
                        >
                          <h2 className="font-section-sub-header-small-bold">
                            Business benefits of&nbsp; Zoho Desk
                            chatbot &nbsp;with Workativ Assistant
                          </h2>

                          <h3 className="font-section-normal-text-medium">
                            1. Visual and intuitive UX 
                          </h3>

                          <p className="font-section-normal-text line-height-2">
                            Workativ Assistant’s easy-to-use{" "}
                            <a href="https://workativ.com/conversational-ai-platform/features">
                              app workflow builder and chatbot builder
                            </a>{" "}
                            lets you create a customized chatbot for a specific
                            team in just a few minutes. Be it for creating a
                            ticket in Zoho Desk or creating an issue in Jira,
                            anyone in your organization can
                            use Workativ Assistant’s chatbot to do so in the
                            blink of an eye. The chatbot resides proactively in
                            your business’ Slack or Microsoft Teams workspace,
                            24×7. And the best part is, it requires no coding!
                            How awesome is that, right? 
                          </p>

                          <h5 className="font-section-normal-text-medium">
                            2. Creating a synergy between apps 
                          </h5>
                          <p className="font-section-normal-text line-height-2">
                            Instead of using your Zoho Desk as simply a system
                            to answer support tickets, integrate it with other
                            applications to add more value and make the
                            information less fragmented.  
                          </p>

                          <h5 className="font-section-normal-text-medium">
                            3. Essential information at fingertips   
                          </h5>
                          <p className="font-section-normal-text line-height-2">
                            With Workativ Assistant’s Zoho Desk integration,
                            your help desk agents can stay on top of assigned
                            tickets and provide outstanding employee service
                            with ease.  Agents can get a list of unassigned
                            tickets or notifications when either a ticket was
                            assigned to them, or when they receive a note or
                            reply on one of their tickets. With the{" "}
                            <a href="https://workativ.com/conversational-ai-platform/ticket-automation-chatbot">
                              AI-powered search,{" "}
                            </a>
                            agents can quickly and easily search and find
                            relevant tickets. 
                          </p>

                          <p className="font-section-normal-text line-height-2">
                            Overall, you can build an efficient helpdesk with
                            the Workativ Assistant integration for Zoho Desk
                            which allows agents to resolve employee issues more
                            quickly and allows supervising managers to monitor
                            the state of the help desk more conveniently. 
                          </p>
                        </div>

                        {/* Section 8*/}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section8"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            Conclusion  
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            Zoho Desk Chatbot provides a number of benefits that
                            can help businesses improve communication and
                            productivity. As a platform, it is easy to use and
                            supports a wide range of languages, making it
                            applicable to a variety of businesses. Additionally,
                            its conversational interface makes it fun and
                            engaging for users, which can lead to increased
                            engagement and productivity. Finally, the chatbot
                            can be customized to fit the needs of individual
                            businesses, making it an ideal tool for improving
                            communication and collaboration within
                            organizations.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            Workativ allows you to create an AI bot for your
                            office in minutes with no coding necessary. Workativ
                            is a no-code SaaS platform that allows organisations
                            to create, automate, and deploy conversational AI
                            chatbots in less than 60 minutes.
                            <a href="https://workativ.com/conversational-ai-platform/zoho-desk-chatbot">
                              &nbsp;Try our FREE Zoho Desk Chatbot now.&nbsp;
                            </a>
                            It's easy to use and can save you time by automating
                            tasks. So why not give it a try today?
                          </p>
                        </div>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a
                                className="blog_usecase_link font-section-normal-text"
                                href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot

                        "
                              >
                                A Guide to Conversational AI Chatbots
                              </a>
                            </li>
                            <li>
                              <a
                                className="blog_usecase_link font-section-normal-text"
                                href="https://workativ.com/conversational-ai-platform/slack-chatbot-guide"
                              >
                                Slack Chatbot Guide for IT Helpdesk Automation
                              </a>
                            </li>
                            <li>
                              <a
                                className="blog_usecase_link font-section-normal-text"
                                href="https://workativ.com/conversational-ai-platform/10-major-it-workflow-automation-benefits "
                              >
                                10 Best IT Workflow Automation Practices to
                                follow for Chatbots designed with Workativ
                                Assistant
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper margin-no-code">
      <h4>No Code - Free ZohoDesk Chatbot</h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="zohodesk chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
